<template>
  <v-app app style="background-color: #0d35310e;">
    <!-- <v-system-bar color="deep-purple darken-3"></v-system-bar> -->
    <v-app-bar app dense :elevation="elevation" color="secondary" class="ml-4 rounded-b-lg">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="green"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <DarkModeVue></DarkModeVue>
      <div class="px-2" @click="sendAlert">
        <v-badge overlap depressed :content="alertsCount > 0 ? alertsCount : '0'">
          <v-btn icon small>
            <v-icon dark :color="alertsCount > 0 ? 'red' : 'black'">mdi-bell</v-icon>
          </v-btn>
        </v-badge>
      </div>
      <v-chip class="ma-2" color="success" outlined>
        <v-icon left>
          mdi-account-outline
        </v-icon>
        {{ role }}
      </v-chip>
      <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-badge bordered bottom color="deep-purple accent-4" dot offset-x="10" offset-y="10">

            <v-avatar color="green" size="40" v-on="on" style="cursor: pointer;">
              <span class="white--text text-h5">
                {{ user.name.charAt(0) + user.lastName.charAt(0) }}
              </span>
            </v-avatar>
          </v-badge>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar color="green">
                <span class="white--text text-h5">{{ user.name.charAt(0) + user.lastName.charAt(0) }}</span>
              </v-avatar>
              <h3>{{ user.name }} {{ user.lastName }}</h3>
              <p class="text-caption mt-1">
                {{ user.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              Rol: {{ role }}
              <v-divider class="my-3"></v-divider>
              <v-btn outlined color="primary" @click="dialogRecovery = !dialogRecovery">
                Cambiar Contraseña
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn outlined color="primary" @click="logout">
                Cerrar Sesión
              </v-btn>
              <v-divider class="my-3"></v-divider>
              v{{ versionApp }}
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer app left permanent touchless :mini-variant.sync="mini" :elevation="elevation" color="primary"
      class="leftSidebar ml-sm-2 mt-sm-2" style="border-radius: 15px !important;">
      <v-list shaped>
        <v-list-item class="px-2 pt-3 d-flex align-center justify-center l">
          <v-img alt="Planti" class="shrink mr-2" contain lazy-src="/img/logo_planti_08.png"
            src="/img/logo_planti_08.png" transition="scale-transition" width="70" />
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense shaped>
        <v-list-item :to="{ name: 'dashboard' }" active-class="active" class="accent-tmp">
          <v-list-item-icon class="color-text-label">
            <v-icon class="" color="#EFEDED">mdi-view-dashboard-variant-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-label color-text-label ">
            Inicio
          </v-list-item-title>
        </v-list-item>


        <v-list-group :value="true" no-action color="white"
          v-if="menuDataAdmin.length > 0 && $vuetify.breakpoint.smAndDown == false">
          <template v-slot:prependIcon>
            <v-icon color="#EFEDED">mdi-cog-outline</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="text-label color-text-label ">Configuración</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(menu, id) in menuDataAdmin" :key="id" :to="{ name: menu.link }" active-class="active"
            class="accent-tmp p-off">
            <v-list-item-icon class="color-text-label">
              <v-icon class="" color="#EFEDED">{{ menu.ico }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-label color-text-label ">
              {{ menu.name_module }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>

        <div v-if="menuDataAdmin.length > 0 && $vuetify.breakpoint.smAndDown != false">
          <v-list-item v-for="(menu, id) in menuDataAdmin" :key="id" :to="{ name: menu.link }" active-class="active"
            class="accent-tmp">
            <v-list-item-icon class="color-text-label">
              <v-icon class="" color="#EFEDED">{{ menu.ico }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-label color-text-label ">
              {{ menu.name_module }}
            </v-list-item-title>
          </v-list-item>
        </div>

        <v-list-item v-for="(menu, id) in menuData" :key="id" :to="{ name: menu.link }" active-class="active"
          class="accent-tmp">
          <v-list-item-icon class="color-text-label">
            <v-icon class="" color="#EFEDED">{{ menu.ico }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="text-label color-text-label ">
            {{ menu.name_module }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list nav dense shaped>
          <v-list-item active-class="active" class="accent-tmp" v-if="apiToken != '' && role == 'Administrador'">
            <button type="button" v-clipboard:copy="apiToken" v-clipboard:success="onCopy" v-clipboard:error="onError">
              <span class="color-text-label size-text-label">
                <v-icon color="white">mdi-clipboard-check-multiple</v-icon>Token API!
              </span>
            </button>
          </v-list-item>
          <v-list-item class="color-text-label">
            <span class="color-text-label version">v{{ versionApp }}</span>
          </v-list-item>
          <v-list-item active-class="active" class="accent-tmp" @click="logout">
            <v-list-item-icon class="color-text-label">
              <v-icon class="" color="#EFEDED">mdi-login-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-label color-text-label ">
              Cerrar Sesión
            </v-list-item-title>
          </v-list-item>
        </v-list>

      </template>
    </v-navigation-drawer>

    <v-main app :elevation="elevation">
      <v-container fluid>
        <v-dialog transition="dialog-top-transition" width="auto" v-model="dialogRecovery">

          <template v-slot:default="{ isActive }">
            <v-card>
              <v-toolbar color="primary" title="Opening from the top"></v-toolbar>
              <v-card-text>
                <div class="text-h2 pa-12">Cambio de Contraseña</div>
              </v-card-text>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col>
                      <v-text-field label="Contraseña Actual" prepend-icon="mdi-form-textbox-password"
                        variant="underlined" v-model="currentPassword" type="password"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field label="Nueva Contraseña" prepend-icon="mdi-form-textbox-password"
                        variant="underlined" v-model="newPassword" type="password"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field label="Confirmar Nueva Contraseña" prepend-icon="mdi-form-textbox-password"
                        variant="underlined" v-model="confirmNewPassword" type="password"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-btn color="primary" v-if="newPassword == confirmNewPassword && newPassword.length > 0"
                        @click="changePassword">Cambiar</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn variant="text" @click="dialogRecovery = !dialogRecovery">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-main fluid class="pa-0 page-wrapper bg-background px-sm-5 px-4 pt-12 rounded-xl">
          <v-card>
            <div class="headline pl-4 bordered">
              <slot name="title"></slot>
            </div>
            <slot name="main"></slot>
          </v-card>

        </v-main>
      </v-container>
    </v-main>
    <OverlayVue :overlay="OverlayVueBool"></OverlayVue>
    <v-snackbar
      v-model="snackbarAlert"
      multi-line
      color="#E53935"
      shaped
      outlined
      right
      middle
      absolute
      centered
      transition="fab-transition"
    >
      ⚠️ Alertas del Sistema: {{ alertsCount }}

      <template #action="{ attrs }">
        <v-btn v-bind="attrs" color="primary" text @click="snackbarAlert = false">
          <v-icon class="" color="#EFEDED">mdi-cancel</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import DarkModeVue from '@/comm/DarkMode.vue';
import { elevation, versionApp } from '@/config/config';
import Storage from '@/store/modules/Storage';
import Auth from '@/http/Auth';
import Setting from '@/http/Setting';
import Alerts from '@/http/Alerts';
import OverlayVue from '@/comm/Overlay.vue';

export default {
  components: {
    DarkModeVue, OverlayVue
  },
  data() {
    return {
      drawer: false,
      group: null,
      menuData: [],
      menuDataAdmin: [],
      breakpoint: false,
      elevation: elevation,
      versionApp: versionApp,
      apiToken: null,
      role: '',
      user: Storage.getUser(),
      alertsCount: 0,
      time: null,
      OverlayVueBool: true,
      dialogRecovery: false,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      snackbarAlert: false,
      countAlerts: 0,
    }
  },
  watch: {
    group() {
      //this.drawer = false
    },
  },
  methods: {
    getMenu() {
      this.menuData = Storage.getMenu();
      this.menuDataAdmin = Storage.getMenuAdmin();
    },
    getApi() {
      Setting.get().then(response => this.apiToken = response.data)
    },
    logout() {
      Storage.removeData();
      this.$router.push({
        name: 'home'
      });
    },
    onCopy() {
      this.$swal({
        icon: 'success',
        title: 'Copia de Token',
        text: 'Se ha Copia el TOKEN para el hardware en su porta papeles'
      })
    },
    onError() {

    },
    alertCountFunction() {
      
      Alerts.alertCount('Activo')
        .then(response => {
          if (response.data.message.length > 0) {
            var local = 0;
            for (let i in response.data.message) {
              if (response.data.message[i].status == 'Activo'){
                local++;
                
              }
            }
            if(this.alertsCount != local && local > 0){
              this.alertsCount = local;
              this.snackbarAlert = true;
            }
            if(this.alertsCount != local){
              this.alertsCount = local;
            }
          } else {
            console.log('No hay alertas');
            this.alertsCount = 0;
          }
        });
    },
    recover() {
      this.load = true;
      Auth.recoveryPassword(this.username)
        .then(response => {
          this.load = false;
          this.$swal('Se ha Enviado el Correo Electrónico con los pasos a seguir');
        });
    },
    sendAlert() {
      this.$router.push({
        name: 'alerts'
      });
    },
    changePassword() {
      Auth.changePassword({ currentPassword: this.currentPassword, newPassword: this.newPassword, confirmNewPassword: this.confirmNewPassword })
        .then(response => {
          if (response.status == 200) {
            this.$swal({
              icon: 'error',
              title: 'Error',
              text: response.data.message
            })
          }
          if (response.status == 201) {
            this.$swal({
              icon: 'success',
              title: 'Exitosamente.........',
              text: response.data.message
            })
              .then((result) => {
                if (result.isConfirmed)
                  this.dialogRecovery = !this.dialogRecovery;
              });
          }
        });
    }
  },
  mounted() {
    this.getMenu();
    this.role = Storage.getRole();
    this.time = setInterval(() => {
      this.alertCountFunction();
    }, 5000);
    this.alertCountFunction();
    if (this.role == 'Administrador')
      this.getApi();

  },
  computed: {
    mini() {
      return (this.$vuetify.breakpoint.smAndDown) || this.drawer;
    }

  },
  destroyed() {
    clearInterval(this.time)
  }
}
</script>

<style>
@font-face {
  font-family: GacorPersonalUse;
  src: local(GacorPersonalUse-Regular),
    url(./../assets/fonts/Gacor.ttf) format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: var(--v-textcolor-base);
  font-size: 16px;
}

.active {
  background-color: rgba(36, 126, 168, 0.438);
  color: rgb(255, 255, 255) !important;
}

.text-label {
  color: rgb(255, 255, 255);
}

.title-primary {
  font-weight: 800 !important;
}

.accent-tmp:hover {
  background-color: rgba(0, 255, 255, 0.566) !important;
  color: white !important;
}

.color-text-label {
  color: rgb(255, 255, 255);
}

.size-text-label {
  font-size: 0.8rem;
}

.v-btn {
  text-transform: none !important;
}

.title-main {
  font-family: GacorPersonalUse;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--v-textcolor-base) !important;
}

.header-table {
  font-family: Roboto;
  font-size: 1.1rem;
  font-weight: bolder;
  line-height: 1;
  letter-spacing: 0.1px;
  text-align: left;
  color: var(--v-textcolor-base) !important;
}

.footer {
  text-align: center;
  color: white;

}

.v-application--is-ltr .v-list-group--no-action>.v-list-group__items>.v-list-item {
  padding-left: 35px;
}

.p-off {
  padding: 0px 0px 0px 30px !important;
}

.version{
  font-size: 0.8rem !important;
  color: white;
}
</style>